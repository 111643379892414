export const environment = {
  production: true,
  slug: 'production',
  apiUrl: 'https://api.shoutly.com',
  baseUrl: 'https://gigs.shoutly.com',
  recaptcha: {
    siteKey: '6LfcbxoeAAAAAAZrNIQpt8y1XEZGgbtXkqtVT9uQ'
  },
  paypal: {
    apiUrl: 'https://api-m.paypal.com', // 'https://api-m.sandbox.paypal.com',
    connectUrl: 'https://www.paypal.com/connect', // 'https://www.sandbox.paypal.com/connect',
    clientId: 'AXCmM_zuoAYVLfH40P92--iU6Y13slF5RmynN0XS-QeISjkbdhCusvBYR5J4pdIqhU2zWrdkk0g7LNfN',
    secret: 'ELCvA3LQFKucR3-_lOPXjrC55uHtTQDmpZgChTS2Bm-_GNJnV5-2zbVm71o5B7212Xsi145lUfmTjDKd'
  },
  google: {
    recaptcha: {
      siteKey: '6LfcbxoeAAAAAAZrNIQpt8y1XEZGgbtXkqtVT9uQ'
    },
    analytics: {
      measurement_id: 'G-RLCGTYVBLM'
    }
  },
  storeEncryptionSecretKey: '2eKuj2JPqBPrGSrr',
  fortnox: {
    clientId: 'RtogwBas0smK'
  }
}
